import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

interface ILoadingUIProps {
  title?: React.ReactNode | string
}

const LoadingUI = (props: ILoadingUIProps) => {
  return (
    <Stack alignItems='center' paddingTop='40vh'>
      <CircularProgress />

      <Typography variant='h5' align='center' fontWeight='bold'>
        {props.title}
      </Typography>
    </Stack>
  )
}

export default LoadingUI
